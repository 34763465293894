.custom-table-email-management thead tr th {
  border-bottom: 1px solid theme("colors.blue.sapphire") !important;
}

.custom-table-email-management thead tr th::before {
  display: none !important;
}

.custom-table-email-management .ant-table-column-sorters > span {
  margin-right: 10px;
}

.custom-table-email-management tbody tr td {
  border-bottom: 1px solid theme("colors.blue.hawkes-blue") !important;
}

.custom-table-email-management thead tr th {
  border-bottom: 1px solid theme("colors.blue.sapphire") !important;
}

.custom-table-email-management .ant-checkbox-inner {
  border-radius: 0px !important;
}

.custom-table-email-management .ant-checkbox-checked .ant-checkbox-inner {
  background-color: theme("colors.blue.sapphire");
  border-color: theme("colors.blue.sapphire");
}

.custom-table-email-management
  .ant-checkbox-indeterminate
  .ant-checkbox-inner:after {
  background-color: theme("colors.blue.sapphire") !important;
  border-color: theme("colors.blue.solitude") !important;
}

.custom-table-email-management .ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #ffffff !important;
  border-color: theme("colors.blue.solitude") !important;
}

.custom-table-email-management
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner
  .custom-table-email-management
  .ant-checkbox:not(.ant-checkbox-disabled):hover
  .ant-checkbox-inner,
.custom-table-email-management
  .ant-checkbox:not(.ant-checkbox-checked):hover
  .ant-checkbox-inner {
  border-color: theme("colors.blue.solitude") !important;
}

.custom-table-email-management
  .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: theme("colors.blue.sapphire") !important;
}

.custom-table-email-management
  .ant-table-tbody
  .ant-table-row
  td:not(:first-child):not(:last-child) {
  border-right: 1px dashed theme("colors.blue.solitude");
}

.custom-table-email-management .ant-table-tbody .ant-table-row td:nth-child(5) {
  border-right: 1px solid theme("colors.blue.solitude") !important;
}

/* .custom-table-email-management .ant-table-wrapper .ant-table-selection-column {
  padding-inline-start: 16px !important;
} */
