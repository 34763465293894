.custom-rangePicker-customer-management .ant-picker-range {
  display: flex;
  width: 224px;
  height: 40px;
}

.custom-rangePicker-customer-management
  .ant-picker-range
  .ant-picker-range-separator,
.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}

@media only screen and (max-width: 600px) {
  .custom-rangePicker-customer-management .ant-picker-range {
    width: 100%;
  }
}

.custom-rangePicker-customer-management
  .ant-picker-range
  .ant-picker-input
  > input::placeholder {
  color: theme("colors.blue.rock-blue") !important;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.custom-rangePicker-customer-management
  .ant-picker-range
  .ant-picker-input
  > input {
  color: theme("colors.blue.sapphire") !important;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.custom-rangePicker-customer-management
  .ant-picker-range
  .ant-picker-active-bar {
  display: none;
}

.custom-rangePicker-customer-management .ant-picker-range span {
  order: -1;
  padding-right: 8px;
}
