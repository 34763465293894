/* Antonio */
@font-face {
  font-family: "Antonio";
  font-style: normal;
  src: url("../fonts/Antonio-Bold.ttf");
}

@font-face {
  font-family: "Antonio";
  font-style: normal;
  src: url("../fonts/Antonio-Light.ttf");
}

@font-face {
  font-family: "Antonio";
  font-style: normal;
  src: url("../fonts/Antonio-Regular.ttf");
}

/* Roboto */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  src: url("../fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  src: url("../fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  src: url("../fonts/Roboto-Regular.ttf");
}

/* Resolve */
@font-face {
  font-family: "Resolve";
  font-style: normal;
  src: url("../fonts/Resolve-Extralight.otf");
}

@font-face {
  font-family: "Resolve";
  font-style: normal;
  src: url("../fonts/Resolve-Light.otf");
}

@font-face {
  font-family: "Resolve";
  font-style: normal;
  src: url("../fonts/Resolve-Semilight.otf");
}

@font-face {
  font-family: "Resolve";
  font-style: normal;
  src: url("../fonts/Resolve-Sans-Light.ttf");
}

@font-face {
  font-family: "Resolve";
  font-style: normal;
  src: url("../fonts/Resolve-Sans-Light.woff");
}

@font-face {
  font-family: "Resolve";
  font-style: normal;
  src: url("../fonts/Resolve-Sans-Light.woff2");
}

@font-face {
  font-family: "Resolve";
  font-style: normal;
  src: url("../fonts/Resolve-Sans-Regular.ttf");
}

@font-face {
  font-family: "Resolve";
  font-style: normal;
  src: url("../fonts/Resolve-Sans-Regular.woff");
}

@font-face {
  font-family: "Resolve";
  font-style: normal;
  src: url("../fonts/Resolve-Sans-Regular.woff2");
}

@font-face {
  font-family: "Resolve";
  font-style: normal;
  src: url("../fonts/Resolve-Sans-Semilight.ttf");
}

@font-face {
  font-family: "Resolve";
  font-style: normal;
  src: url("../fonts/Resolve-Sans-SemiLight.woff");
}

@font-face {
  font-family: "Resolve";
  font-style: normal;
  src: url("../fonts/Resolve-Sans-SemiLight.woff2");
}
