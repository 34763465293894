.custom-drawer-edo-import {
  ::-webkit-scrollbar {
    width: 0px;
  }
}

.custom-drawer-edo-import
  .ant-drawer-content-wrapper
  .ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-header
  .ant-drawer-header-title {
  flex-direction: row-reverse;
}

.custom-drawer-edo-import
  .ant-drawer-content-wrapper
  .ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-header
  .ant-drawer-title {
  color: theme("colors.red.base");
  font-family: "Antonio";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.26px;
}

.custom-drawer-edo-import .ant-drawer-body .ant-collapse {
  border: none;
  background-color: transparent;
}

.custom-drawer-edo-import .ant-drawer-body {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-drawer-edo-import .ant-drawer-close {
  margin-inline-end: 0px;
}

.custom-drawer-edo-import .ant-drawer-header {
  border: none;
}

.custom-drawer-edo-import .ant-drawer-body .ant-collapse .ant-collapse-item {
  border-bottom: 1px solid theme("colors.blue.solitude");
}

.custom-drawer-edo-import
  .ant-drawer-body
  .ant-collapse
  .ant-collapse-item:last-child {
  border-bottom: 1px solid theme("colors.blue.solitude");
  border-radius: 0px;
}

.custom-drawer-edo-import
  .ant-drawer-body
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-header {
  color: theme("colors.blue.sapphire");
  font-family: "Resolve";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 16px 24px;
}

.custom-drawer-edo-import
  .ant-drawer-body
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box {
  padding: 20px 24px;
}

.custom-drawer-edo-import .custom-footer {
  box-shadow: 0px -3px 33px 0px rgba(0, 0, 0, 0.07);
  background: white;
}

.custom-drawer-edo-import .ant-form-horizontal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
