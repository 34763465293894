/* Edo list */
/* You better not touch this */
/* ----PLEASE MAKE SURE YOU UNDERSTAND WHAT HAPPEND IN HERE BEFORE MAKING ANY CHANGES----- */
/* Care about "!important" order*/

.custom-table-edo-import .ant-table-content thead tr th,
.custom-child-table-edo-import .ant-table-content thead tr th {
  color: theme("colors.blue.sapphire") !important;
  font-family: "Roboto" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}

.custom-table-edo-import .ant-table-thead {
  border-bottom: 1px solid theme("colors.blue.sapphire");
}

.custom-table-edo-import thead tr th::before {
  display: none !important;
}
.custom-table-edo-import .ant-checkbox-inner {
  border-radius: 0px !important;
}

.custom-table-edo-import .ant-checkbox-checked .ant-checkbox-inner {
  background-color: theme("colors.blue.sapphire");
  border-color: theme("colors.blue.sapphire");
}

.custom-table-edo-import .ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: theme("colors.blue.sapphire") !important;
  border-color: theme("colors.blue.solitude") !important;
}
.custom-child-table-edo-import .ant-table-content thead tr th {
  color: theme("colors.blue.sapphire") !important;
  font-family: "Roboto" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}
.custom-table-edo-import .ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #ffffff !important; /* antd base color, need to reset it */
  border-color: theme("colors.blue.solitude") !important;
}

.custom-table-edo-import
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner
  .custom-table-edo-import
  .ant-checkbox:not(.ant-checkbox-disabled):hover
  .ant-checkbox-inner,
.custom-table-edo-import
  .ant-checkbox:not(.ant-checkbox-checked):hover
  .ant-checkbox-inner {
  border-color: theme("colors.blue.solitude") !important;
}

.custom-table-edo-import
  .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: theme("colors.blue.sapphire") !important;
}

/*end edo import list */

/* new table style */
.custom-table-edo-import .ant-table-content > table {
  display: flex;
  flex-direction: column;
}

.custom-table-edo-import .ant-table-thead tr {
  display: flex;
  justify-content: space-between;
  background-color: theme("colors.sWhite.base");
}

.custom-table-edo-import .ant-table-thead tr td {
  order: 1;
  padding: 12px;
}

.custom-child-table-edo-import .ant-table-thead tr td {
  order: 0;
}

.custom-table-edo-import .ant-table-thead tr th {
  align-items: center;
  display: flex;
  padding: 12px;
}

.custom-table-edo-import .ant-table-tbody .ant-table-row {
  display: flex;
  border-bottom: 1px solid theme("colors.blue.solitude");
  justify-content: start;
}
/* reset */
.custom-child-table-edo-import
  .ant-table-tbody
  .ant-table-row:not(:last-child) {
  border-bottom: 1px solid theme("colors.blue.solitude");
}
.custom-child-table-edo-import .ant-table-tbody .ant-table-row:last-child {
  border-bottom: none;
}
/* end reset */

.custom-table-edo-import .ant-table-tbody .ant-table-row td:first-child {
  order: 1;
  justify-content: center;
  border-left: 1px solid theme("colors.blue.solitude") !important;
}

.custom-child-table-edo-import .ant-table-tbody .ant-table-row td:first-child {
  justify-content: center;
  border-left: 1px solid theme("colors.blue.solitude") !important;
}

.custom-child-table-edo-import .ant-table-tbody .ant-table-row td:first-child {
  border-left: none !important;
}

.custom-child-table-edo-import .ant-table-tbody .ant-table-row td:first-child {
  border-right: none !important;
}

.custom-table-edo-import .ant-table-tbody .ant-table-row td {
  display: flex;
  align-items: center;
}

.custom-table-edo-import
  .ant-table-tbody
  .ant-table-row
  td:not(:nth-child(2)):not(:first-child) {
  border-right: 1px dashed theme("colors.blue.solitude");
}

:root {
  --visible-border-edit-bl: 1px dashed theme("colors.blue.solitude");
}

.custom-table-edo-import .ant-table-tbody .ant-table-row td:nth-child(7) {
  border-right: var(--visible-border-edit-bl) !important;
}

.custom-child-table-edo-import .ant-table-tbody .ant-table-row td:nth-child(7) {
  border-right: 1px dashed theme("colors.blue.solitude") !important;
}

.custom-child-table-edo-import
  .ant-table-tbody
  .ant-table-row
  td:nth-child(10) {
  border-right: none !important;
}

.custom-child-table-edo-import .ant-table-tbody .ant-table-row td:last-child {
  border-left: 1px solid theme("colors.blue.solitude");
}

.custom-table-edo-import .ant-table-tbody .ant-table-row td:last-child {
  border-right: none !important;
}

.custom-child-table-edo-import .ant-table-tbody .ant-table-row td:nth-child(5) {
  border-right: 1px dashed theme("colors.blue.solitude") !important;
}

.custom-child-table-edo-import .ant-table-tbody .ant-table-row td {
  border-right: 1px dashed theme("colors.blue.solitude");
}

.custom-table-edo-import .ant-table-wrapper .ant-table-selection-column {
  padding-inline-start: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-table-edo-import .ant-table-expanded-row {
  display: flex;
}

.custom-table-edo-import .ant-table-expanded-row > .ant-table-cell {
  padding: 0px;
  width: 100%;
}

/* revert parent css */
.custom-child-table-edo-import .ant-table-content > table {
  border-left: 1px solid theme("colors.blue.solitude");
  border-radius: 0px;
}

/* revert parent css */
.custom-child-table-edo-import .ant-table-tbody .ant-table-row td:first-child {
  order: 0;
}
.custom-child-table-edo-import .ant-spin-nested-loading .ant-spin-container {
  margin-left: 70px;
  border: none !important;
}

.custom-table-edo-import .ant-table-tbody .ant-table-row td,
.custom-table-edo-import .ant-table-thead tr .ant-table-cell {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 17.7%;
  flex-grow: 1;
}

@media only screen and (max-width: 1822px) {
  .custom-table-edo-import .ant-table-tbody .ant-table-row td,
  .custom-table-edo-import .ant-table-thead tr .ant-table-cell {
    flex-basis: 15.7%;
    flex-grow: 1;
  }
}
@media only screen and (max-width: 1485px) {
  .custom-table-edo-import .ant-table-tbody .ant-table-row td,
  .custom-table-edo-import .ant-table-thead tr .ant-table-cell {
    flex-basis: 12%;
    flex-grow: 1;
  }
}
@media only screen and (max-width: 1087px) {
  .custom-table-edo-import .ant-table-tbody .ant-table-row td,
  .custom-table-edo-import .ant-table-thead tr .ant-table-cell {
    flex-basis: 10.7%;
    flex-grow: 1;
  }
}

.custom-table-edo-import .ant-table-tbody tr .ant-table-cell:nth-child(8),
.custom-table-edo-import .ant-table-thead tr .ant-table-cell:nth-child(8),
.custom-table-edo-import .ant-table-thead tr .ant-table-cell:nth-child(2),
.custom-table-edo-import
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell:nth-child(2),
.custom-table-edo-import .ant-table-thead tr .ant-table-cell:nth-child(1),
.custom-table-edo-import
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell:nth-child(1) {
  flex-basis: 50px;
  flex-grow: 0;
  max-width: 50px;
}

.custom-child-table-edo-import .ant-table-tbody tr td,
.custom-child-table-edo-import .ant-table-thead tr .ant-table-cell,
.custom-child-table-edo-import .ant-table-thead tr .ant-table-cell:nth-child(2),
.custom-child-table-edo-import
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(2) {
  flex-basis: 2.95% !important;
  flex-grow: 1;
}

.custom-child-table-edo-import .ant-table-thead tr .ant-table-cell:nth-child(1),
.custom-child-table-edo-import
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(1) {
  flex-basis: 50px !important;
  flex-grow: 0;
  padding: 8px !important;
}

.custom-child-table-edo-import .ant-table-thead tr .ant-table-cell:last-child,
.custom-child-table-edo-import .ant-table-tbody tr .ant-table-cell:last-child {
  flex-basis: 50px !important;
  flex-grow: 0;
}

.custom-child-table-edo-import
  .ant-table-thead
  tr
  .ant-table-cell:nth-child(10),
.custom-child-table-edo-import .ant-table-thead tr .ant-table-cell:nth-child(9),
.custom-child-table-edo-import
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(10),
.custom-child-table-edo-import
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(9) {
  flex-basis: 50px !important;
  flex-grow: 0;
}

.custom-table-edo-import .ant-table-empty tbody {
  display: flex;
  justify-content: center;
}

.custom-table-edo-import .ant-table-empty .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

/* ------------------------------------ */
.custom-child-table-edo-import .ant-table-tbody .ant-table-row td:nth-child(4) {
  flex-basis: 15% !important;
}

.custom-child-table-edo-import
  .ant-table-thead
  tr
  .ant-table-cell:nth-child(4) {
  flex-basis: 15% !important;
}

.custom-table-edo-import
  .ant-table-tbody
  .ant-table-row
  td:not(:nth-child(2)):not(:first-child) {
  padding: 8px !important;
}

/* ------------------------------------ */

@media only screen and (max-width: 1822px) {
  .custom-child-table-edo-import .ant-table-tbody tr td,
  .custom-child-table-edo-import .ant-table-thead tr .ant-table-cell,
  .custom-child-table-edo-import
    .ant-table-thead
    tr
    .ant-table-cell:nth-child(2),
  .custom-child-table-edo-import
    .ant-table-tbody
    tr
    .ant-table-cell:nth-child(2) {
    flex-basis: 3.7% !important;
  }
}
@media only screen and (max-width: 1485px) {
  .custom-child-table-edo-import .ant-table-tbody tr td,
  .custom-child-table-edo-import .ant-table-thead tr .ant-table-cell,
  .custom-child-table-edo-import
    .ant-table-thead
    tr
    .ant-table-cell:nth-child(2),
  .custom-child-table-edo-import
    .ant-table-tbody
    tr
    .ant-table-cell:nth-child(2) {
    flex-basis: 1.4% !important;
  }
}
@media only screen and (max-width: 1269px) {
  .custom-child-table-edo-import .ant-table-tbody tr td,
  .custom-child-table-edo-import .ant-table-thead tr .ant-table-cell,
  .custom-child-table-edo-import
    .ant-table-thead
    tr
    .ant-table-cell:nth-child(2),
  .custom-child-table-edo-import
    .ant-table-tbody
    tr
    .ant-table-cell:nth-child(2) {
    flex-basis: 3% !important;
  }
  .custom-child-table-edo-import
    .ant-table-tbody
    .ant-table-row
    td:nth-child(4) {
    flex-basis: 20% !important;
  }

  .custom-child-table-edo-import
    .ant-table-thead
    tr
    .ant-table-cell:nth-child(4) {
    flex-basis: 20% !important;
  }
}
@media only screen and (max-width: 1087px) {
  .custom-child-table-edo-import .ant-table-tbody tr td,
  .custom-child-table-edo-import .ant-table-thead tr .ant-table-cell,
  .custom-child-table-edo-import
    .ant-table-thead
    tr
    .ant-table-cell:nth-child(2),
  .custom-child-table-edo-import
    .ant-table-tbody
    tr
    .ant-table-cell:nth-child(2) {
    flex-basis: 5% !important;
  }
  .custom-child-table-edo-import
    .ant-table-tbody
    .ant-table-row
    td:nth-child(4) {
    flex-basis: 15% !important;
  }

  .custom-child-table-edo-import
    .ant-table-thead
    tr
    .ant-table-cell:nth-child(4) {
    flex-basis: 15% !important;
  }
}

.custom-child-table-edo-import .ant-table-thead tr .ant-table-cell:nth-child(8),
.custom-child-table-edo-import .ant-table-tbody tr .ant-table-cell:nth-child(8),
.custom-child-table-edo-import
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(8) {
  flex-basis: 8% !important;
  flex-grow: 1;
}

.custom-child-table-edo-import .ant-table-thead tr .ant-table-cell:nth-child(7),
.custom-child-table-edo-import .ant-table-tbody tr .ant-table-cell:nth-child(7),
.custom-child-table-edo-import
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(7) {
  flex-basis: 14% !important;
  flex-grow: 1;
  max-width: 146px;
}

.custom-child-table-edo-import .ant-table-thead tr .ant-table-cell:nth-child(8),
.custom-child-table-edo-import .ant-table-tbody tr .ant-table-cell:nth-child(8),
.custom-child-table-edo-import
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(8) {
  max-width: 120px;
}

.custom-child-table-edo-import .ant-table-thead tr .ant-table-cell:nth-child(2),
.custom-child-table-edo-import
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(2) {
  flex-basis: 0% !important;
  flex-grow: 0 !important;
  padding: 0px;
}

.custom-child-table-edo-import
  .ant-table-thead
  tr
  .ant-table-cell:nth-child(2)::before {
  background-color: transparent !important;
}

.custom-child-table-edo-import .ant-table-thead tr .ant-table-cell:nth-child(3),
.custom-child-table-edo-import
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(3) {
  flex-basis: 60px !important;
  max-width: 130px;
}

/* trigger and apply only with depot role */
.custom-child-table-edo-import-for-depot
  .ant-table-tbody
  .ant-table-row
  td:nth-child(8) {
  border-right: none !important;
}

.custom-child-table-edo-import-for-depot
  .ant-table-thead
  tr
  .ant-table-cell:nth-child(7),
.custom-child-table-edo-import-for-depot
  .ant-table-thead
  tr
  .ant-table-cell:nth-child(8),
.custom-child-table-edo-import-for-depot
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(8),
.custom-child-table-edo-import-for-depot
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(7) {
  flex-basis: 50px !important;
  flex-grow: 0;
}

.custom-child-table-edo-import-for-depot
  .ant-table-thead
  tr
  .ant-table-cell:nth-child(6),
.custom-child-table-edo-import-for-depot
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(6),
.custom-child-table-edo-import-for-depot
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(6) {
  flex-basis: 14% !important;
  flex-grow: 1;
  max-width: 146px;
}

.custom-child-table-edo-import-for-depot
  .ant-table-thead
  tr
  .ant-table-cell:nth-child(5),
.custom-child-table-edo-import-for-depot
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(5),
.custom-child-table-edo-import-for-depot
  .ant-table-tbody
  tr
  .ant-table-cell:nth-child(5) {
  flex-basis: 15% !important;
  flex-grow: 1;
  max-width: 350px;
}

.custom-table-edo-import tbody,
.custom-table-edo-import table {
  overflow: auto;
}
