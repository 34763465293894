.custom-table-discharge-management thead tr td {
  border-bottom: 1px solid theme("colors.blue.sapphire") !important;
}

.custom-table-discharge-management
  .ant-table-tbody
  .ant-table-row
  td:nth-child(5) {
  border-right: 1px dashed theme("colors.blue.solitude") !important;
}

.custom-table-discharge-management
  .ant-table-tbody
  .ant-table-row
  td:nth-child(6) {
  border-right: 1px solid theme("colors.blue.solitude") !important;
}

.custom-table-accounts-management thead tr td {
  border-bottom: 1px solid theme("colors.blue.sapphire") !important;
}
.custom-table-discharge-management
  .ant-table-tbody
  .ant-table-row
  td:first-child {
  border-right: 1px none !important;
}

.custom-table-email-management .ant-table-tbody .ant-table-row td:first-child {
  border-right: 1px dashed #d9dce8;
}

.custom-table-accounts-management
  .ant-table-tbody
  .ant-table-row
  td:nth-child(7) {
  border-right: 1px dashed theme("colors.blue.solitude") !important;
}

.custom-table-accounts-management
  .ant-table-tbody
  .ant-table-row
  td:nth-child(8) {
  border-right: 1px solid theme("colors.blue.solitude") !important;
}
