/* custom select style */
.custom-account-select-dropdown .ant-select-item-option-disabled > div {
  color: theme("colors.blue.rock-blue") !important;
}

.custom-account-select-dropdown {
  border-radius: theme("borderRadius.none");
  border: 1px solid theme("colors.blue.sapphire");
  padding: theme("spacing.0");
}

.custom-account-select .ant-select-selector .ant-select-selection-item {
  font-size: theme("fontSize.sm");
  line-height: theme("spacing.4");
  font-family: theme("fontFamily.roboto");
  color: theme("colors.blue.sapphire");
  font-weight: theme("fontWeight.normal");
}

.custom-account-select.ant-select-single .ant-select-selector {
  padding: theme("spacing.3");
  box-shadow: none !important;
  border-radius: 0 !important;
  font-size: theme("fontSize.sm");
  border: 1px solid theme("colors.blue.waikawa-grey") !important;
}

.custom-account-select-dropdown .ant-select-item.ant-select-item-option {
  padding: theme("spacing.2") theme("spacing.3");
  border-radius: theme("borderRadius.none");
}

.custom-account-select-dropdown
  .ant-select-item.ant-select-item-option.ant-select-item-option-active,
.custom-account-select-dropdown
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: theme("colors.blue.solitude-light") !important;
}

.custom-account-select-dropdown .ant-select-item-option-content {
  font-family: theme("fontFamily.roboto");
  font-size: theme("fontSize.base");
  line-height: theme("spacing.6");
  color: theme("colors.blue.sapphire");
}

/* Select group */
.custom-select-popup-group {
  border-radius: theme("borderRadius.none");
  border: 1px solid theme("colors.blue.sapphire");
  padding: theme("spacing.0");
}

.custom-select-popup-group .ant-select-item-group {
  font-family: theme("fontFamily.roboto");
  color: theme("colors.blue.sapphire");
  font-size: theme("fontSize.base");
  font-weight: theme("fontWeight.bold");
  line-height: theme("lineHeight.6");
  padding: theme("spacing.2") theme("spacing.3");
}

.custom-select-popup-group .ant-select-item.ant-select-item-option {
  padding: theme("spacing.2") theme("spacing.8");
  border-radius: theme("borderRadius.none");
}

.custom-select-popup-group .ant-select-item-option-content {
  font-family: theme("fontFamily.roboto");
  font-size: theme("fontSize.base");
  line-height: theme("spacing.6");
  font-weight: theme("fontWeight.normal");
  color: theme("colors.blue.sapphire");
}

.custom-select-popup-group
  .ant-select-item.ant-select-item-option.ant-select-item-option-active,
.custom-select-popup-group
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: theme("colors.blue.solitude-light") !important;
}
